import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store';

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'inicio',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta:{
      libre:true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta:{
      libre:true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },
  {
    path: '/vehiculos',
    name: 'vehiculos',
    component: () => import(/* webpackChunkName: "vehiculos" */ '../views/Vehiculos.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },

  {
    path: '/historial',
    name: 'historial',
    component: () => import(/* webpackChunkName: "historial" */ '../views/Historial.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },


  {
    path: '/configuracion',
    name: 'configuracion',
    component: () => import(/* webpackChunkName: "configuracion" */ '../views/Empresas.vue'),
    meta:{
      administrador:true,
      usuario_comun:false
    }
  },

  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import(/* webpackChunkName: "usuarios" */ '../views/Usuarios.vue'),
    meta:{
      administrador:true,
      usuario_comun:false
    }
  },

  {
    path: '/alertas',
    name: 'alertas',
    component: () => import(/* webpackChunkName: "usuarios" */ '../views/Alertas.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },





];

const router = new VueRouter({
  mode: 'history',
  routes
});






router.beforeEach((to, from, next)=>{



  //si alguien recarga la url debemos tomar ese token e ingresarlo al state

const token_storage=localStorage.getItem('token');

if(token_storage!=null){


  if(!store.state.usuario){

    console.log('token encontrado');
    console.log(token_storage);
    store.dispatch("guardarToken", token_storage);
  }

}




  if(to.matched.some(record=>record.meta.libre)){
    next();


    //validamos rutas solo administrador
  }else if( store.state.usuario && store.state.usuario.rol=='ADMIN'){

    if(to.matched.some(record=>record.meta.administrador)){
      next();
    }

    //ruta para usuarios comunes
  }else if(store.state.usuario && store.state.usuario.rol=='USER'){

    if(to.matched.some(record=>record.meta.usuario_comun)){
          next();
        }

    //si no tiene ningun permiso salir
  }else{
    next({name:'login'});

    
    console.log('entro en ruta libre');

  }

});




export default router


